import React, { useState } from "react";

import { send } from "emailjs-com";

const initialState = {
    name: "",
    email: "",
    message: "",
};
const initialErrorState = {
    name: "",
    email: "",
    message: "",
};

const ContactForm = () => {
    const [input, setInput] = useState(initialState);
    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        if (input.name === "" || input.email === "" || input.message === "") {
            setError({
                name: input.name === "" ? "Name cannot be empty" : "",
                email: input.email === "" ? "Email cannot be empty" : "",
                message: input.message === "" ? "Message cannot be empty" : "",
            });
        } else {
            send(
                "service_lx7ntmx",
                "template_8ois3mw",
                input,
                "user_gBjUuaPZclykxkEB9PNCX"
            )
                .then((response) => {
                    console.log("SUCCESS!", response.status, response.text);
                    setError(initialErrorState);
                    setSuccess(true);
                    setInput(initialState);
                    setTimeout(() => setSuccess(false), 2000);
                })
                .catch((err) => {
                    console.log("FAILED...", err);
                });
        }
    };
    return (
        <form
            onSubmit={onSubmit}
            className={`flex flex-col m-1 w-full max-w-xs p-4 ${
                success ? "bg-green-200" : "bg-gray-100"
            } rounded gap-2`}
        >
            Name:
            <input
                type="text"
                size="19"
                name="Name"
                value={input.name}
                onChange={(e) =>
                    setInput((prev) => ({ ...prev, name: e.target.value }))
                }
            />
            <p className="text-red-500 text-xs text-center h-4">{error.name}</p>
            Email:
            <input
                type="email"
                name="Email"
                value={input.email}
                onChange={(e) =>
                    setInput((prev) => ({ ...prev, email: e.target.value }))
                }
            />
            <p className="text-red-500 text-xs text-center h-4">
                {error.email}
            </p>
            Message:
            <textarea
                name="Message"
                rows="6"
                cols="20"
                value={input.message}
                onChange={(e) =>
                    setInput((prev) => ({ ...prev, message: e.target.value }))
                }
            />
            <p className="text-red-500 text-xs text-center h-4">
                {error.message}
            </p>
            <button type="submit">
                {!success ? "Submit" : "Message Sent"}
            </button>
        </form>
    );
};

export default ContactForm;
