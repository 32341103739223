import ContactForm from "./ContactForm";

const hashes = ["", "#about", "#work", "#contact"];

function App() {
    function onScroll(e) {
        const index = e.target.scrollTop / window.innerHeight;
        if (Number.isInteger(index) && window.location.hash !== hashes[index])
            window.location.hash = hashes[index];
    }

    return (
        <div className="App snap-container" onScroll={onScroll}>
            <section className="relative hero w-screen h-screen snap-section">
                <nav className="p-4 md:px-8">
                    <a
                        href="/"
                        className="font-serif text-xl sm:text-2xl decoration-none text-black"
                    >
                        Ikartt
                    </a>
                    <span className="flex gap-3 md:gap-8">
                        <a className="decoration-none text-black" href="#about">
                            About
                        </a>
                        <a className="decoration-none text-black" href="#work">
                            Our Work
                        </a>
                        <a
                            className="decoration-none text-black"
                            href="#contact"
                        >
                            Contact
                        </a>
                    </span>
                </nav>

                <div className="background" />
                <h1 className="text-3xl md:text-5xl font-bold">
                    The new way to consume art.
                </h1>
            </section>
            <section
                id="about"
                className="w-screen h-screen flex justify-center sm:items-center p-4 text-justify snap-section"
            >
                <div className="max-w-screen-md">
                    <h2 className="text-2xl md:text-3xl font-bold mb-4">
                        Ikartt Studio
                    </h2>
                    <p>
                        Ikartt Studio brings art in to the digital age. We are
                        an experienced team of developers working with artists
                        to turn their art into NFTs.
                    </p>
                    <h3 className="text-xl font-bold my-4">What is an NFT?</h3>
                    <p>
                        An NFT &#39;Non Fungible Token&apos; shows exclusive
                        ownership of a particular digital asset (e.g., a piece
                        of art, an in-game purchase, or a tweet). NFTs can be
                        considered modern-day collectibles artworks. They are
                        bought and sold online, and represent a digital proof of
                        ownership of any given item. NFTs are securely recorded
                        on a blockchain - the same technology behind
                        cryptocurrencies - which ensures the asset is
                        one-of-a-kind. The blockchain technology makes it
                        impossible to alter or counterfeit NFTs.
                    </p>
                </div>
            </section>
            <section
                className="portfolio w-screen h-screen md:grid md:grid-cols-2 snap-section"
                id="work"
            >
                <img
                    className="h-1/2 w-full object-cover md:h-screen"
                    src="/shark.jpg"
                    alt="Shark from Pasqua's exhibition in Monaco"
                />
                <div className="p-4 text-justify justify-start sm:mt-8">
                    <h2 className="sm:text-2xl md:text-3xl font-bold mb-2 text-gray-500">
                        Our Work
                    </h2>
                    <hr className="solid my-2 sm:my-12" />
                    <span className="bg-yellow-300 text-xl float-right">
                        01 November
                    </span>
                    <h3 className="text-xl mb-2">Philippe Pasqua</h3>
                    <h2 className="font-bold text-2xl sm:text-4xl mb-4">
                        Funny Death Maze
                    </h2>

                    <p>
                        Funny Death Maze is the first NFT project made by
                        Philippe Pasqua.
                    </p>
                </div>
            </section>
            <section
                className="contact w-screen h-screen flex flex-col items-center p-4 snap-section"
                id="contact"
            >
                <h3 className="font-bold text-2xl sm:text-4xl my-4 md:mt-24">
                    Contact Us
                </h3>
                <ContactForm />
            </section>
            <footer className="p-8 flex w-screen h-64 flex justify-center items-center snap-section">
                <h4 className="font-serif text-lg">© Ikartt 2020</h4>
            </footer>
        </div>
    );
}

export default App;
